import styles from '@styles/scss/common/_heroSection.module.scss';
import { Category } from 'lib/types/item/category';
import { useEffect, useState } from 'react';

export default function HeroContent({
    category,
    currentLang
}: {
    category: Category;
    currentLang: string;
}): JSX.Element {
    const [showMore, setShowMore] = useState(false);
    const [width, setWidth] = useState(0);
    const [intro, setIntro] = useState('');
    useEffect(() => {
      setIntro(category.intro);
    }, [category.intro]);

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResizeWindow);
        handleResizeWindow();

        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, []);

    const delimiter = currentLang === 'zh' || currentLang === 'ja' ? '。' : '.';
    const introParts = intro.split(delimiter);
    let sectionOne = `${introParts[0]}${delimiter}${introParts[1]}... `;
    const hiddenSection = introParts.slice(2).join(delimiter);

    return (
        <main className={styles.showReadMore}>
            <h1>{category.category_name}</h1>
            <div className={styles.intro}>
                <p>
                    {showMore || width >= 450 ? (
                        <span dangerouslySetInnerHTML={{ __html: intro }} />
                    ) : (
                        <span dangerouslySetInnerHTML={{ __html: sectionOne }} />
                    )}
                    {!showMore && width < 450 && (
                        <span className={styles.hiddenContent} style={{ display: showMore ? 'inline' : 'none' }}>
                            <span dangerouslySetInnerHTML={{ __html: hiddenSection }} />
                        </span>
                    )}
                    <span
                        role="presentation"
                        className={`${styles.showButton} ${showMore ? styles.showLess : ''}`}
                        onClick={() => setShowMore(!showMore)}
                        onKeyDown={() => setShowMore(!showMore)}
                    >
                        {showMore ? 'Show Less' : 'Show More'}
                        <svg
                            width="12"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d={showMore ? 'M11 6L6 1L1 6' : 'M11 1L6 6L1 1'}
                                stroke="#222222"
                                strokeMiterlimit="10"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </p>
            </div>
        </main>
    );
    };
